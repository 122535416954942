import http from "@/utils/http";
const resquest = `${process.env.VUE_APP_BASE_URL}`;

// 检查优惠券是否可用
export function checkDiscount(data) {
  return http.post(
    `${resquest}/user/discount/checkDiscount`, 
    data
  );
}
