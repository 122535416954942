<template>
  <div class="shopping-cart">
    <div class="main">
     
      <div>
        <!-- 没有订单数据 -->
        <div class="not-data-content" v-if="orderData.length == 0">
          <img width="77" height="70" src="../assets/img/icon_shopping_car.png" />
          <div class="not-data-text">暂无订单</div>
        </div>
        <!-- 物流订单内容 -->
        <div class="shopping-cart-content" v-else>
          <!-- 标题 -->
          <div class="title">物流订单</div>
          <!-- 订单显示列表 -->
          <div class="order-show">
            <div class="table-div">
              <el-table
                :data="orderData"
                style="width: 100%"
                border
                empty-text="暂无数据"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="59" :resizable="false">
                </el-table-column>
                <el-table-column
                  prop="orderNum"
                  label="订单号"
                  width="187"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column
                  prop="serviceName"
                  label="服务路线"
                  width="139"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column
                  prop="senderName"
                  label="寄件人"
                  width="121"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column
                  prop="consigneeName"
                  label="收件人"
                  width="79"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column
                  prop="destination"
                  label="目的地"
                  width="155"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column
                  prop="weight"
                  label="重量(KG)"
                  width="81"
                  :resizable="false"
                >
                </el-table-column>
                <el-table-column prop="price" label="金额(£)" :resizable="false">
                  <template slot-scope="scope">
                    <div class="amount-div">
                      <span class="amount-text">{{ scope.row.price }}</span>
                      <el-popover
                        placement="bottom"
                        width="200"
                        trigger="hover"
                        popper-class="amount-div-detail-content"
                      >
                        <div class="amount-detail-content">
                          <div class="detail-item">
                            <div class="detail-text">国际运费</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.internationalShipping }}
                            </div>
                          </div>
                          <div class="detail-item" v-if="false">
                            <div class="detail-text">境内费用</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.inCharge }}
                            </div>
                          </div>
                          <div class="detail-item">
                            <div class="detail-text">预缴税金</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.prepaidTax }}
                            </div>
                          </div>
                          <div class="detail-item" v-if="false">
                            <div class="detail-text">附加费</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.additionalCharge }}
                            </div>
                          </div>
                          <div class="detail-item">
                            <div class="detail-text">保险费</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.insuranceCost }}
                            </div>
                          </div>
                          <div class="detail-item">
                            <div class="detail-text">燃油附加费</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.fuelSurcharge }}
                            </div>
                          </div>
                          <div class="detail-item" v-if="false">
                            <div class="detail-text">增值服务费</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.addedServiceFee }}
                            </div>
                          </div>
                          <div class="detail-item" v-if="false">
                            <div class="detail-text">折扣优惠</div>
                            <div class="detail-text">
                              {{ scope.row.allAmount.discounts }}
                            </div>
                          </div>
                          <div class="all-detail-item">
                            <div class="detail-text">费用合计</div>
                            <div class="all-amount-text">
                              {{ scope.row.allAmount.amount }}
                            </div>
                          </div>
                        </div>
                        <img
                          slot="reference"
                          width="12"
                          src="../assets/img/icon_question_nor.png"
                        />
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="93" :resizable="false">
                  <template slot-scope="scope">
                    <div class="edit-div">
                      <div
                        class="edit-text posi"
                        @click="eidtClicked(scope.$index, scope.row)"
                      >
                        编辑
                      </div>
                      <div class="space-text posi">｜</div>
                      <div
                        class="edit-text posi"
                        @click="deletedClicked(scope.$index, scope.row)"
                      >
                        删除
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 小计金额 -->
          <!-- <div class="all-amount-div">
          <div class="tip">小计金额：</div>
          <div class="all-amount">£{{allAmount}}</div>
        </div> -->
        
        </div>
        <!-- 折扣码 -->
        <div class="discount-code">
          <div class="discount-code-title">使用折扣码</div>
          <el-col :span="7">
            <el-input 
            type="text" 
            v-model="inputCode"
            class="discount-code-input"   placeholder="请输入内容"></el-input>
          </el-col>
          <el-col :span="11" class="ml-10">
            <el-button type="primary" :loading="codeButtonLoad" @click="checkDiscount">使用</el-button>
          </el-col>
        </div>
      </div>
    

      

      <!-- 底部结算内容 -->
      <div class="shopping-cart-bottom">
        <div class="bottom-div">
          <!-- 表格操作和快捷操作 -->
          <div class="item-show">
            <!-- <div class="edit-text posi" @click="delecteCheckClicked">删除选中的项</div> -->
            <div class="edit-text posi" @click="toHomeClicked">
              返回首页继续下单
            </div>
          </div>
          <!-- 结算显示和操作 -->
          <div class="item-show right-show">
            <div>
              <div class="discount-content">
                <div class="tip-text">优惠(英镑)：</div>
                <div>&nbsp;{{ discountAmount }}</div>
              </div>
              <div class="amount-content">
                <div class="tip-text">应付金额(英镑)：</div>
                <div class="amount-text">{{ checkedAmount }}</div>
              </div>
            </div>
            
            <div class="settle-btn posi" @click="settlement">去结算</div>
          </div>
        </div>
      </div>
      <!-- 编辑订单 -->
      <div class="order-div">
        <el-drawer
          @open="handleDrawerOpen"
          @closed="handleDrawerClosed"
          :title="'订单：' + needOrderData.orderNum"
          :visible.sync="showOrderEidt"
          direction="rtl"
          :size="1005"
          :modal-append-to-body="false"
          :close-on-press-escape="false"
          :wrapperClosable="false"
        >
          <div class="drawer-content">
            <!-- 金额提示 -->
            <!-- <div class="hint-div" v-if="hintShow">
            <div class="hint-left">
              <div class="amount-hint-text">当前总额：</div>
              <div class="amount-hint-text">£{{needOrderData.amount}}</div>
              <div class="hint-text">修改包裹的尺寸、重量可能会重新计算总额，请知悉</div>
            </div>
            <i class="el-icon-close hint-close posi" @click="hintShow = false"></i>
          </div> -->
            <!-- 寄件人信息 -->
            <div class="sender-div">
              <Sender
                ref="packageSender"
                :sender="needOrderData.sender"
                :show="false"
                @updateSender="updateSender"
              ></Sender>
            </div>
            <!-- 收件人信息 -->
            <div class="recipients-div">
              <Addressee
                :addressArr="needOrderData.addressArr"
                :gateSign="gateSign"
                :show="false"
                ref="address"
                @updateAddress="updateAddress"
              ></Addressee>
            </div>
            <!-- 操作按钮 -->
            <div class="btn-div">
              <div class="cance-btn posi" @click="showOrderEidt = false">
                取消
              </div>
              <div class="save-btn posi" @click="saveClicked">确定保存</div>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    
  </div>
</template>

<script>
import Sender from "../components/order/sender.vue";
import Addressee from "../components/order/addressee.vue";
import {
  showShoppingCartList,
  deleteOrderOfUnpay,
  getOrderPackageDetail,
  updateOrderPackagePeopleInfo,
  payOrderMoneyByRecharge,
  getAllInsurance,
} from "@/api/order/order";
import {checkDiscount} from "@/api/discount/discount";
import { getQueryVariable } from "@/utils/common";
export default {
  name: "ShoppingCart",
  components: {
    Sender,
    Addressee,
  },
  data() {
    return {
      codeButtonLoad: false,
      inputCode: "",
      //折扣
      discount: {
        //折扣码
        code: "",
        //折扣类型，0表示固定金额，1表示固定比例
        type: 0,
        //折扣金额或比例
        amount: 0,
      },
      //关口
      gateSign: "",
      settlementLoading: false, // 去结算loading
      //订单数据
      orderData: [],
      // 选择的订单
      multipleSelection: [],
      // 是否显示订单编辑页面
      showOrderEidt: false,
      // 是否显示金额提示
      hintShow: true,
      // 被编辑的订单金额
      needOrderData: {
        // 被编辑的订单号
        orderNum: "",
        // 当前订单的id
        id: "",
        // 被编辑的订单金额
        amount: "",
        // 寄件人数据
        sender: {
          name: "寄件人姓名",
          phone: "电话",
          zipCode: "邮编",
          addressOne: "必填项",
          addressTwo: "必填项",
          addressThree: "非必填项",
          addressFour: "非必填项",
          hideSetNew: true,
        },
        // 收件人数组
        addressArr: [
          {
            // 收件人信息  姓名，手机号，身份证号，城市，详细地址
            consignee: {
              name: "", // 收件人姓名
              identityNumber: "", // 身份证号
              phone: "", // 收件人手机号
              province: "", // 省
              city: "", // 市
              county: "", // 区
              zipCode: "", // 邮编
              addressDetail: "", // 详细地址
              retained: [], // 三级联动值
            },
            // 包裹信息
            packageList: [
              {
                // 保险服务
                insuranceId: "",
                // 包裹备注
                note: "",
                // 物品
                goodsList: [
                  {
                    // 物品名称
                    goodsId: "",
                    // 物品数量
                    number: 1,
                    // 物品单价
                    price: "",
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    //小计金额
    allAmount: {
      get: function () {
        let acount = 0;
        this.orderData.forEach((item) => {
          acount += parseFloat(Number(item.price));
        });
        return acount.toFixed(2);
      },
    },
    //未使用优惠前应付金额
    totalAmount: {
      get: function () {
        let acount = 0;
        this.multipleSelection.forEach((item) => {
          acount += parseFloat(item.price);
        });
        return acount.toFixed(2);
      },
    },
    //应付金额
    checkedAmount: {
      get: function () {
        if(this.totalAmount - this.discountAmount<0){
          return 0;
        }
        return (this.totalAmount - this.discountAmount).toFixed(2);
      },
    },
    //折扣金额
    discountAmount: {
      get: function () {
        if (this.discount.type == 0) {
          //折扣金额 
          return this.discount.amount.toFixed(2);
        } else if (this.discount.type == 1) {
          //折扣比例
          return ((this.totalAmount * this.discount.amount) / 100).toFixed(2);
        } else {
          return 0;
        }
      }
    }
  },
  created() {
    let flag = getQueryVariable("flag");
    if (flag == 0) {
      this.$message.error("已取消支付");
      let old_url = window.location.href;
      let new_url = old_url.substring(0, old_url.indexOf("?"));
      self.location = new_url; //1.当前直接替换了路径到url，实际上还是会进行跳转
      history.replaceState(null, null, new_url); //2.替换地址栏,直接将地址栏参数替换
    } else if (flag == 1) {
      this.$message.success("支付成功");
    }
    this.showShoppingCartList();
  },
  methods: {
    //检查折扣码
    checkDiscount() {
      if (this.inputCode == "") {
        return this.$message.error("请填写折扣码");
      }
      if (this.multipleSelection.length == 0) {
        return this.$message.error("请勾选使用的订单");
      }
      let orderNums = this.multipleSelection.map(item => {
        return item.orderNum;
      });
      this.codeButtonLoad = true;
      checkDiscount({
        "discountCode": this.inputCode,
        "orderNum": orderNums
      }).then(res => {
        this.codeButtonLoad = false;
        console.log("checkDisoucnt res", res);
        if (res.data.code != 0) {
          return this.$message.error(res.data.message);
        }
        this.discount.type = res.data.data.type;
        this.discount.amount = res.data.data.amount;
        this.discount.code = res.data.data.code;
        
      }).catch(err => {
        console.log("checkDiscount err", err);
        this.codeButtonLoad = false;
        return this.$message.error("暂时无法使用");
      });
    },
 
    toHomeClicked() {
      this.$router.push({
        path: "/Home",
      });
    },
    // 点击去结算
    settlement() {
      // 整理选中结算的订单
      let ids = [];
      let sn = [];
      this.multipleSelection.forEach((item) => {
        ids.push(item.id);
        sn.push(item.orderNum);
      });
      // 需要支付的订单id存到vux中
      if (ids.length === 0) {
        this.$message.error("请选择要支付的订单");
        return;
      }

      this.$store.state.paymentObj.ids = ids;
      this.$store.state.paymentObj.sn = sn;
      this.$store.state.paymentObj.price = this.checkedAmount;
      this.$store.state.paymentObj.discountCode = this.discount.code;

      this.$router.push({
        path: "/paymentMethod",
      });
      // if(this.settlementLoading){
      //   return
      // }
      // this.settlementLoading = true
      // // 整理选中结算的订单
      // let ids = [];
      // this.multipleSelection.forEach(item=>{
      //   ids.push(item.id)
      // });
      // // 调用结算接口
      // this.payOrderMoneyByRecharge(ids)
    },
    // 支付接口
    payOrderMoneyByRecharge(ids) {
      let upData = {
        successPath: `${window.location.href}`,
        orderIds: ids,
        failPath: `${window.location.href}`,
      };
      payOrderMoneyByRecharge(upData)
        .then((res) => {
          if (res.data.code === 0) {
            let url = res.data.data.toPath;
            window.location.href = url;
          } else {
            this.$message.error(res.data.message);
          }
          this.settlementLoading = false;
        })
        .catch(() => {
          this.settlementLoading = false;
        });
    },
    // 查询所有未支付订单
    showShoppingCartList() {
      showShoppingCartList().then((res) => {
        if (res.data && res.data.code == 0) {
          this.orderData = res.data.data.list;
          console.log(this.orderData, "this.orderData");
        }
      });
    },
    // 删除选中的未支付订单
    deleteOrderOfUnpay(id) {
      let upData = [id];
      deleteOrderOfUnpay(upData).then((res) => {
        if (res.data && res.data.code == 0) {
          this.$message.success(res.data.message);
          this.showShoppingCartList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 查询订单详情
    getOrderPackageDetail(id) {
      let upData = {
        orderId: id,
      };
      getOrderPackageDetail(upData).then((res) => {
        if (res.data && res.data.code == 0) {
          console.log("res", res.data);
          // 寄件人信息
          this.needOrderData.sender = res.data.data.result.sender;
          this.needOrderData.sender.hideSetNew = true;
          // 收件人信息
          this.needOrderData.addressArr = res.data.data.result.addressArr;
          // 修改服务路线
          this.$store.dispatch("updateLineId", res.data.data.result.lineId);
          // 打开侧边栏
          this.showOrderEidt = true;
          this.gateSign = res.data.data.result.clcSign;
          this.getAllInsurance(res.data.data.result.lineId);
        }
      });
    },
    // 获取保险接口
    getAllInsurance(lineId) {
      let upData = {
        lineId: lineId,
      };
      getAllInsurance(upData).then((res) => {
        if (res.data.code === 0) {
          this.$store.state.InsuranceOptions = res.data.data.list;
          this.$store.state.insurance = res.data.data.list[0].id;
          console.log(
            this.$store.state.insurance,
            "this.$store.state.insurance"
          );
        }
      });
    },
    // 选择的面单发生变化
    handleSelectionChange(args) {
      console.log("选择的面单列表发生变化", args);
      this.multipleSelection = args;
    },
    // 操作按钮被点击
    eidtClicked(arg1, arg2) {
      // console.log("操作按钮被点击", arg1,arg2);
      this.getOrderPackageDetail(arg2.id);
      this.needOrderData.orderNum = arg2.orderNum;
      this.needOrderData.id = arg2.id;

      // this.needOrderData.amount = arg2.price;
    },
    // 删除按钮被点击
    deletedClicked(arg1, arg2) {
      console.log("删除按钮被点击", arg1, arg2);
      // this.orderData.splice(arg1,1);
      this.deleteOrderOfUnpay(arg2.id);
    },
    // 删除所选项被点击
    delecteCheckClicked() {
      console.log("删除所选项被点击");
      let _this = this;
      this.multipleSelection.forEach((item) => {
        let index = _this.orderData.findIndex((order) => order == item);
        _this.orderData.splice(index, 1);
      });
      this.multipleSelection = [];
    },
    // 侧边打开
    handleDrawerOpen() {
      console.log("侧边打开");
      this.hintShow = true;
    },
    // 侧边关闭
    handleDrawerClosed() {
      //清空侧边栏数据
      // this.needOrderData = {};
    },
    // 选择了地址簿中的寄件人信息使用
    updateSender(arg) {
      this.needOrderData.sender.name = arg.name;
      this.needOrderData.sender.phone = arg.phone;
      this.needOrderData.sender.zipCode = arg.zipCode;
      this.needOrderData.sender.addressOne = arg.addressOne;
      this.needOrderData.sender.addressTwo = arg.addressTwo;
      this.needOrderData.sender.addressThree = arg.addressThree;
      this.needOrderData.sender.addressFour = arg.addressFour;
    },
    // 选择了地址簿中的收件人信息使用
    updateAddress(arg1, index) {
      this.needOrderData.addressArr[index].consignee.name = arg1.name;
      this.needOrderData.addressArr[index].consignee.identityNumber =
        arg1.identityNumber;
      this.needOrderData.addressArr[index].consignee.phone = arg1.phone;
      this.needOrderData.addressArr[index].consignee.province = arg1.province;
      this.needOrderData.addressArr[index].consignee.city = arg1.city;
      this.needOrderData.addressArr[index].consignee.county = arg1.county;
      this.needOrderData.addressArr[index].consignee.zipCode = arg1.postCode;
      this.needOrderData.addressArr[index].consignee.addressDetail =
        arg1.addressDetail;
      this.needOrderData.addressArr[index].consignee.retained = arg1.retained;
    },
    // 点击保持按钮
    saveClicked() {
      let temp1 = false;
      let temp2 = false;
      // 寄件人验证输入
      this.$refs.packageSender.$refs.senderForm.validate((valid) => {
        if (valid) {
          temp1 = true;
        } else {
          alert("寄件人格式错误");
          return;
        }
      });

      // 收件人验证
      // 所有收件人信息校验
      let addressRef =
        this.$refs.address.$refs.addressForm.$refs["addressForm"];
      // 所有包裹表单
      let packageRef = [];
      // 整理去重后需要验证的所有表单
      let validateArr = [];

      // 遍历所有收件人组件
      for (let i = 0; i < addressRef.length; i++) {
        // 获取所有包裹组件
        let packageTemp = this.$refs.address.$refs.addressForm.$refs.package;
        // 遍历所有包裹
        for (let i = 0; i < packageTemp.length; i++) {
          // 把每个包裹中的物品表单添加到包裹表单数组中
          packageRef.push(...new Set(packageTemp[i].$refs.packageForm));
          console.log(
            packageTemp[i].$refs.sizeForm,
            "packageTemp[i].$refs.sizeForm"
          );
          packageRef.push(...new Set(packageTemp[i].$refs.sizeForm));
        }
      }
      // 合并 物品表单以及收件人表单并去重
      validateArr = [...new Set(packageRef), ...new Set(addressRef)];
      // 记录所有表单验证的结果， 全是 true 验证通过，有一个false 验证失败
      let resArr = [];
      // 循环所有需要验证的表单进行验证
      for (let i = 0; i < validateArr.length; i++) {
        validateArr[i].validate((valid) => {
          if (valid) {
            // 验证成功往结果数组添加true
            resArr.push(true);
          } else {
            // 验证失败往结果数组添加false
            resArr.push(false);
            return false;
          }
        });
      }
      // 判断表单验证结果数组中有没有false 有，阻止请求接口
      if (resArr.includes(false)) {
        alert("收件人或包裹格式错误");
        return;
      } else {
        // 表单验证成功，请求接口
        temp2 = true;
      }
      if (temp1 & temp2) {
        // console.log(this.needOrderData, 'needOrderData')
        console.log("成功");
        // 更新接口调用
        this.upDataOrder();
        this.showOrderEidt = false;
        this.$refs.packageSender.setAddress();
      }
    },
    // 保存更新
    upDataOrder() {
      let upData = {
        consigneeAddressDetail:
          this.needOrderData.addressArr[0].consignee.addressDetail, // 收件人具体地址
        consigneeCity: this.needOrderData.addressArr[0].consignee.retained[1], // 收件人城市
        consigneeCounty: this.needOrderData.addressArr[0].consignee.retained[2], // 收件人县/区
        consigneeIdentityNumber:
          this.needOrderData.addressArr[0].consignee.identityNumber, // 收件人身份证号
        consigneeName: this.needOrderData.addressArr[0].consignee.name, // 收件人姓名
        consigneePhone: this.needOrderData.addressArr[0].consignee.phone, // 收件人手机号
        consigneePostCode: this.needOrderData.addressArr[0].consignee.zipCode, // 收件人邮编
        consigneeProvince:
          this.needOrderData.addressArr[0].consignee.retained[0], // 收件人省份
        orderId: this.needOrderData.id, // 订单Id
        senderAddressFour: this.needOrderData.sender.addressFour, // 寄件人地址3
        senderAddressOne: this.needOrderData.sender.addressOne, // 寄件人城市
        senderAddressThree: this.needOrderData.sender.addressThree, // 寄件人地址2
        senderAddressTwo: this.needOrderData.sender.addressTwo, // 寄件人地址1
        senderName: this.needOrderData.sender.name, // 寄件人姓名
        senderPostCode: this.needOrderData.sender.zipCode, // 寄件人邮编
        senderpPhone: this.needOrderData.sender.phone, // 寄件人手机号
      };
      console.log(upData, "upData");
      updateOrderPackagePeopleInfo(upData).then((res) => {
        if (res.data && res.data.code == 0) {
          this.$message.success(res.data.message);
          this.showShoppingCartList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>

<style>
/* 抽屉的样式 */
.order-div .el-drawer__header {
  margin-bottom: 0px;
  padding: 30px;
  border-bottom: 1px solid #e5daeb;
  font-size: 16px;
  font-weight: 400;
  color: #1e2b4f;
}
.order-div [class*=" el-icon-"],
.order-div [class^="el-icon-"] {
  color: #6d5efe;
}

/* 金额悬浮框样式 */
.amount-div-detail-content {
  background: #ffffff !important;
  box-shadow: 0px 2px 10px 0px rgba(37, 76, 151, 0.3) !important;
  border-radius: 8px !important;
  padding: 16px 0px 0px 0px !important;
  border: 0px !important;
}
.amount-div-detail-content .detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0px 12px 0px 16px;
}
.amount-div-detail-content .detail-item .detail-text {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #636d88;
  line-height: 20px;
}
.amount-div-detail-content .all-detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background: #f3f4fd;
  border-radius: 0px 0px 8px 8px;
  padding: 0px 12px 0px 16px;
}
.amount-div-detail-content .all-detail-item .all-amount-text {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #ff6400;
  line-height: 22px;
}
.discount-code-input.el-input .el-input__inner{
  border-radius: 5px;
}
</style>

<style lang="less" scoped>
.shopping-cart {
  background-color: #f4f4f4;
  min-height: 100%;
  .main {
    display: flex;
    justify-content: center;
    // 无数据时的内容
    .not-data-content {
      width: 1000px;
      min-height: calc(100vh - 101px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .not-data-text {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #636d88;
        line-height: 22px;
        margin-top: 20px;
      }
    }
    // 购物车数据内容
    .shopping-cart-content {
      margin-top: 20px;
      width: 1000px;
      background: #ffffff;
      border-radius: 8px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      margin-bottom: 50px;
      .title {
        height: 33px;
        font-size: 24px;
        font-weight: bold;
        color: #1e2b4f;
        line-height: 33px;
        margin: 30px;
      }
      .order-show {
        .amount-div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .amount-text {
            margin-right: 4px;
          }
        }
        .amount-div img:hover {
          content: url(../assets/img/icon_question_hov.png);
        }
        .edit-div {
          display: flex;
          flex-direction: row;
          justify-content: center;
          .edit-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #6d5efe;
            line-height: 20px;
          }
          .space-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #b1b6c3;
            line-height: 20px;
          }
        }
      }
      .all-amount-div {
        margin: 30px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .tip {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #1e2b4f;
          line-height: 20px;
        }
        .all-amount {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #ff6400;
          line-height: 20px;
        }
      }
    }
    // 购物车底部结算内容
    .shopping-cart-bottom {
      position: absolute;
      z-index: 10;
      bottom: 0px;
      left: 0px;
      width: 100%;
      min-width: 1400px;
      height: 100px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      .bottom-div {
        width: 1000px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-show {
          display: flex;
          align-items: center;
          .discount-content{
            font-size: 14px;
            
          }
          .discount-content{
            display: flex;
            align-items: baseline;
          }
          .amount-content{
            display: flex;
            align-items: baseline;
            
          }
          .tip-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            width: 120px;
            text-align: right;
          }
          .amount-text {
            height: 33px;
            font-size: 24px;
            font-weight: bold;
            color: #ff6400;
            line-height: 33px;
            margin: 0px 5px 0px 5px;
          }
          .edit-text {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #6d5efe;
            line-height: 20px;
            margin-right: 20px;
          }
          
          .settle-btn {
            width: 88px;
            height: 44px;
            background: #ff6400;
            border-radius: 22px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            line-height: 44px;
            margin-left: 12px;
            margin-right: 20px;
          }
        }
      }
    }
    // 订单编辑界面
    .order-div {
      .drawer-content {
        .hint-div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 940px;
          height: 62px;
          background: #fffbe6;
          border-radius: 8px;
          border: 1px solid #ffc17c;
          margin-left: 30px;
          margin-top: 30px;
          .hint-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 24px;
            .amount-hint-text {
              height: 30px;
              font-size: 20px;
              font-weight: bold;
              color: #5c2f00;
              line-height: 30px;
            }
            .hint-text {
              height: 30px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #5c2f00;
              line-height: 30px;
              // margin-left: 34px;
            }
          }
          .hint-close {
            font-size: 20px;
            color: #5c2f00;
            line-height: 20px;
            margin-right: 20px;
          }
        }
        .sender-div {
          border-bottom: 1px solid #e5daeb;
        }
        .recipients-div {
          margin-bottom: 90px;
        }
        .btn-div {
          position: absolute;
          z-index: 10;
          bottom: 0px;
          left: 0px;
          right: 0px;
          height: 100px;
          background: #fff;
          border-top: 1px solid #e5daeb;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .cance-btn {
            width: 100px;
            height: 44px;
            background: #ffffff;
            border-radius: 22px;
            border: 1px solid #d2d5dc;
            font-size: 16px;
            font-weight: 400;
            color: #636d88;
            text-align: center;
            line-height: 44px;
          }
          .save-btn {
            width: 100px;
            height: 44px;
            background: #6d5efe;
            border-radius: 22px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            line-height: 44px;
            margin-left: 20px;
          }
        }
      }
    }
    //折扣码
    .discount-code{
      margin-bottom: 200px;
      .discount-code-title{
        font-size: 20px;
        margin-bottom: 10px;
      }
      .ml-10{
        margin-left: 10px;
      }

    }
    
  }
}

</style>
